const msalConfig = {
    auth: {
      clientId: 'c3a666a4-683b-467e-a7a7-b92078514933',
      authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
      redirectUri: 'https://omni-internal-mgrtools.deloitte.com/',
      responseType: 'code',
      scopes: ['email'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

export default msalConfig;