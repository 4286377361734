const amplifyConfig = {
    Auth: {
      identityPoolId: 'us-east-1:2c3bc98c-021b-45ec-aacb-8c23624243cc',
      region: 'us-east-1',
      userPoolId: 'us-east-1_ko62UGxwx',
      userPoolWebClientId: '4l0bs166bh85s5nq61vbtn4q1r',
      oauth: {
        domain: 'gps-sa.auth.us-east-1.amazoncognito.com',
        scope: ['email'],
        redirectSignIn: 'https://utils.ov-003.deloitte.com/',
        redirectSignOut: 'https://utils.ov-003.deloitte.com/',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
      federationTarget: 'COGNITO_USER_POOL',
      identityProvider: 'AzureAD',
      config: {
        metadataUrl: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/federationmetadata/2007-06/federationmetadata.xml?appid=329d3dd1-ca27-4852-8321-920e2e64a298',
        serviceProviderName: 'urn:amazon:cognito:sp:us-east-1_ko62UGxwx',
        assertionConsumerServiceURL: 'https://utils.ov-003.deloitte.com/',
      },
    },
    API: {
      endpoints: [
        {
          name: "GPS-SA", // name of the API in API Gateway console
          endpoint: "https://3ps0b21m78.execute-api.us-east-1.amazonaws.com/prod",
          region: "us-east-1",
          paths: ['/']
        }
      ]
    }
  }

export default amplifyConfig;