// Import the environment variable
const env = process.env.REACT_APP_NAME;

let amplifyConfig;

if (env === 'd10') {
    amplifyConfig = require('./d10/amplifyConfig.js').default;
} else if (env === 'gps_poc') {
    amplifyConfig = require('./gps_poc/amplifyConfig.js').default;
} else if (env === 'gps_sa') {
    amplifyConfig = require('./gps_sa/amplifyConfig.js').default;
} else {
    amplifyConfig = require('./ec2booter/amplifyConfig.js').default;
}

export default amplifyConfig;
