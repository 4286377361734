import './App.css';
import { Amplify, Auth } from 'aws-amplify';
import '@fontsource/inter/variable.css';
import '@aws-amplify/ui-react/styles.css';
import { PublicClientApplication } from '@azure/msal-browser';
import React, { useState } from 'react';
import AuthenticatedUserView from './components/AuthenticatedUserView/AuthenticatedUserView';
import AmplifySignIn from './components/AmplifySignIn/AmplifySignIn';
// import configuration files
import amplifyConfig from './config/amplifyConfig';
import authConfig from './config/authConfig';
import msalConfig from './config/msalConfig';

Amplify.configure(amplifyConfig);
Auth.configure(authConfig);

function App() {
  const [email, setEmail] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const msalInstance = new PublicClientApplication(msalConfig);

  const handleSignIn = async () => {
    try {
      const authResult = await msalInstance.loginPopup({
        scopes: ['User.Read', 'openid', 'email'],
        responseType: 'token',
      });

      const accessTokenResult = authResult.accessToken;
      const userEmail = authResult.account.username;
      setEmail(userEmail);

      setAccessToken(accessTokenResult);
    } catch (error) {
      console.log('Error signing in:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      setAccessToken(null);
      setEmail(null);
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  return (
    <div>
      <h1>Omni Internal Management Tools</h1>
      {process.env.NODE_ENV === 'production' ? (
        accessToken ? (
          <div>
            <button onClick={handleSignOut}>Sign Out</button>
            <AuthenticatedUserView accessToken={accessToken} email={email} />
          </div>
        ) : (
          <button onClick={handleSignIn}>Sign In</button>
        )
      ) : (
        <AmplifySignIn />
      )}
    </div>
  );
}

export default App;
