import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function CostUsageChart({ costInfo }) {
  return (
    <LineChart width={600} height={300} data={costInfo}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Cost" stroke="blue" name="Cost" />
    </LineChart>
  );
}

export default CostUsageChart;
