const amplifyConfig = {
    Auth: {
      identityPoolId: 'us-east-1:f225aba2-72a8-4626-88b1-f21b22e99137',
      region: 'us-east-1',
      userPoolId: 'us-east-1_dx9p8GzuJ',
      userPoolWebClientId: 'iop2uftum7kocec7b7nvpasln',
      oauth: {
        domain: 'ecbooterapp.auth.us-east-1.amazoncognito.com',
        scope: ['email'],
        redirectSignIn: 'https://utils.ov-002.deloitte.com/',
        redirectSignOut: 'https://utils.ov-002.deloitte.com/',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
      federationTarget: 'COGNITO_USER_POOL',
      identityProvider: 'AzureAD',
      config: {
        metadataUrl: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/federationmetadata/2007-06/federationmetadata.xml?appid=1628586a-6f87-401f-8d42-bb7832398695',
        serviceProviderName: 'urn:amazon:cognito:sp:us-east-1_dx9p8GzuJ',
        assertionConsumerServiceURL: 'https://utils.ov-002.deloitte.com/',
      },
    },
    API: {
      endpoints: [
        {
          name: "dimension10", // name of the API in API Gateway console
          endpoint: "https://br6hfwnv7f.execute-api.us-east-1.amazonaws.com/prod",
          region: "us-east-1",
          paths: ['/']
        }
      ]
    }
  }

export default amplifyConfig;