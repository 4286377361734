const msalConfig = {
    auth: {
      clientId: 'f04644cb-82fd-44bf-b4b6-73d0e1712cba',
      authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
      redirectUri: 'https://ec2booter.deloitte.com/',
      responseType: 'code',
      scopes: ['User.Read', 'openid', 'profile'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

export default msalConfig; 