const msalConfig = {
    auth: {
      clientId: '1628586a-6f87-401f-8d42-bb7832398695',
      authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
      redirectUri: 'https://utils.ov-002.deloitte.com/',
      responseType: 'code',
      scopes: ['email'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

export default msalConfig;