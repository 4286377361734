// Import the environment variable
const env = process.env.REACT_APP_NAME;

let authConfig;

if (env === 'd10') {
    authConfig = require('./d10/authConfig.js').default;
} else if (env === 'gps_poc') {
    authConfig = require('./gps_poc/authConfig.js').default;
} else if (env === 'gps_sa') {
    authConfig = require('./gps_sa/authConfig.js').default;
} else {
    authConfig = require('./ec2booter/authConfig.js').default;
}

export default authConfig;