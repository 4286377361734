import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { API } from 'aws-amplify';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Loader,
  SwitchField,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@aws-amplify/ui-react';
import amplifyConfig from "../../config/amplifyConfig";
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import CostUsageChart from '../CostUsageChart/CostUsageChart';
import './EC2InstanceTable.css';

const Ec2InstanceTable = (props) => {
  const accessToken = props.accessToken;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const [data, setData] = useState(props.data);
  const [loader, setLoader] = useState(null);
  const [isReportVisibleArray, setIsReportVisibleArray] = useState([]);
  const [costInfo, setCostInfo] = useState([]);

  // color styles for EC2 instance status
  const styles = {
    running: 'green',
    stopped: 'red',
    pending: 'darkyellow',
    stopping: 'darkyellow'
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const toggleReportVisibility = async (index, event, toggle, startDate = null, endDate = null) => {
    event.preventDefault();
    setCostInfo([]);

    const newVisibilityArray = [...isReportVisibleArray];

    // Set all elements to false
    for (let i = 0; i < newVisibilityArray.length; i++) {
      if (i !== index)
        newVisibilityArray[i] = false;
    }

    // Toggle the specified index
    if (toggle)
      newVisibilityArray[index] = !newVisibilityArray[index];

    setIsReportVisibleArray(newVisibilityArray);


    if (newVisibilityArray[index]) {
      const instanceIds = data.map(item => item.id);
      // Calculate the startDate two months ago from today
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

      const init_cost_info = {
        body: {
          instanceIds,
          startDate: (!startDate) ? formatDate(twoMonthsAgo) : formatDate(startDate),
          endDate: (!endDate) ? formatDate(new Date()) : formatDate(endDate),
        },
        headers
      };
      const getCostInfoResponse = await API.post(amplifyConfig.API.endpoints[0].name, '/get_cost_info', init_cost_info);
      setCostInfo(getCostInfoResponse);
    }
  };

  const toggleInstance = (instance_id, i) => {
    setLoader(i);

    const fetchData = async () => {
      try {
        const userEmail = props.email;
        const region = props.region;

        const init = {
          body: {
            user: userEmail,
            region,
            instance_id
          },
          headers
        };

        await API.post(amplifyConfig.API.endpoints[0].name, '/start_stop_instances', init);

        const callApi = async () => {
          try {
            const getInstancesResponse = await API.post(amplifyConfig.API.endpoints[0].name, '/get_ec2_instances', init);
            const index = getInstancesResponse.findIndex(obj => obj.id === instance_id);
            setData(getInstancesResponse);
            setLoader(index);

            // Create an array with the same length as the data and initialize it to false
            const initialVisibilityArray = Array(getInstancesResponse.length).fill(false);
            setIsReportVisibleArray(initialVisibilityArray);

            if (
              getInstancesResponse[index].state.includes('running') ||
              getInstancesResponse[index].state.includes('stopped')
            ) {
              setLoader(null);
              return;
            } else {
              setTimeout(callApi, 5000);
            }
          } catch (error) {
            console.error(error);
          }
        };

        callApi();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  };

  return (
    <div data-testid="Ec2InstanceTable">
      <h1>Your EC2 Instances</h1>

      <Table
        highlightOnHover={true}
        size={'default'}
        variation={'bordered'}
      >
        <TableHead>
          <TableRow>
            <TableCell as="th">Instance state</TableCell>
            <TableCell as="th">Name</TableCell>
            <TableCell as="th">Username(s)</TableCell>
            <TableCell as="th">Private IPv4 address</TableCell>
            <TableCell as="th">Launch time</TableCell>
            <TableCell as="th">Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(((instance, index) => {
            const filteredCostInfo = costInfo.filter(data => data.InstanceID === instance.id);
            // Calculate the total cost for the current instance
            const totalCost = filteredCostInfo.reduce((acc, data) => acc + data.Cost, 0);

            return (
              <>
                <TableRow>
                  <TableCell style={{ color: styles[instance.state] }}>
                    {loader !== index ? (
                      <SwitchField
                        isDisabled={false}
                        defaultChecked={instance.state === 'running' ? true : false}
                        onChange={() => toggleInstance(instance.id, index)}
                      />
                    ) : (
                      <Loader />
                    )}
                    &emsp;
                    <b>{instance.state}</b>
                  </TableCell>
                  <TableCell>{instance.name}</TableCell>
                  <TableCell>
                    {instance.user_tag.split('|').map((user, userIndex) => (
                      <React.Fragment key={userIndex}>
                        {user}
                        {userIndex !== instance.user_tag.split('|').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell>
                    {instance.ip_address && (
                      <>
                        {instance.ip_address}
                      </>
                    )}
                  </TableCell>
                  <TableCell><Moment format="LLL">{instance.launch_time}</Moment></TableCell>
                  <TableCell>
                    {instance.admin ? (
                      <>
                        <font color="green"><b>Yes</b></font>
                      </>
                    ) : (
                      <>
                        <font color="maroon"><b>No</b></font>
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {instance.admin && (
                  <TableRow>
                    <TableCell key={index} colSpan={6}>
                      <a href="#" className='show-cost-link' onClick={(event) => toggleReportVisibility(index, event, true)}>
                        {isReportVisibleArray[index] ? '- Hide Cost and Usage Report' : '+ Show Cost and Usage Report'}
                      </a>
                      <br /><br />
                      {isReportVisibleArray[index] && (
                        <div>
                          <DateRangeSelector index={index} toggleReportVisibility={toggleReportVisibility} />
                          <CostUsageChart costInfo={filteredCostInfo} />

                          <div className="cost-info-container">
                            {filteredCostInfo.length === 0 ? (
                              <div>Loading...</div>
                            ) : (
                              <>
                                <div><b>Total Cost:</b> ${totalCost}</div>
                                {filteredCostInfo
                                  .map((data, dataIndex) => (
                                    <div key={dataIndex} className="cost-info-item">
                                      Date: {data.Date}, Cost: ${data.Cost}
                                    </div>
                                  ))}
                              </>
                            )}

                          </div>

                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </>)
          }
          ))}
        </TableBody>
      </Table>
    </div>
  )
};

Ec2InstanceTable.propTypes = {
  email: PropTypes.string,
  accessToken: PropTypes.string,
  user: PropTypes.object,
  region: PropTypes.string,
};

Ec2InstanceTable.defaultProps = {};

export default Ec2InstanceTable;
