import React, { useState } from 'react';

// Define a list of AWS regions
const awsRegions = [
  'us-east-1',
  'us-west-2',
  'ap-south-1',
];

const RegionSelector = ({ onRegionChange }) => {
  const [region, setRegion] = useState('us-east-1');

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion);
    onRegionChange(selectedRegion);
  };

  return (
    <div className="region-selector">
    Select Region&emsp;
      <select value={region} onChange={handleRegionChange}>
        {awsRegions.map((r) => (
          <option key={r} value={r}>
            {r}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RegionSelector;
