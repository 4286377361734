const authConfig = {
    oauth: {
      domain: 'dimension10.auth.us-east-1.amazoncognito.com',
      scope: ['email'],
      redirectSignIn: 'https://omni-internal-mgrtools.deloitte.com/',
      redirectSignOut: 'https://omni-internal-mgrtools.deloitte.com/',
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  }

export default authConfig;