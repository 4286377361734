const msalConfig = {
    auth: {
      clientId: '329d3dd1-ca27-4852-8321-920e2e64a298',
      authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
      redirectUri: 'https://utils.ov-003.deloitte.com/',
      responseType: 'code',
      scopes: ['email'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

export default msalConfig;