const amplifyConfig = {
    Auth: {
      identityPoolId: 'us-east-1:cdca8179-6bf2-4615-b6dd-d98e1ac57a73',
      region: 'us-east-1',
      userPoolId: 'us-east-1_qBblXPvJg',
      userPoolWebClientId: '25efpb78miuobdf57pefarf3qm',
      oauth: {
        domain: 'dimension10.auth.us-east-1.amazoncognito.com',
        scope: ['email'],
        redirectSignIn: 'https://omni-internal-mgrtools.deloitte.com/',
        redirectSignOut: 'https://omni-internal-mgrtools.deloitte.com/',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
      federationTarget: 'COGNITO_USER_POOL',
      identityProvider: 'AzureAD',
      config: {
        metadataUrl: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/federationmetadata/2007-06/federationmetadata.xml?appid=c3a666a4-683b-467e-a7a7-b92078514933',
        serviceProviderName: 'urn:amazon:cognito:sp:us-east-1_qBblXPvJg',
        assertionConsumerServiceURL: 'https://omni-internal-mgrtools.deloitte.com/',
      },
    },
    API: {
      endpoints: [
        {
          name: "dimension10", // name of the API in API Gateway console
          endpoint: "https://guimtlhmb2.execute-api.us-east-1.amazonaws.com/prod",
          region: "us-east-1",
          paths: ['/']
        }
      ]
    }
  }

export default amplifyConfig;