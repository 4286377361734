import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import EC2InstanceTable from '../EC2InstanceTable/EC2InstanceTable';
import amplifyConfig from "../../config/amplifyConfig";

export const getEC2Instances = async (accessToken, userEmail, region) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const init = {
    body: {
      user: userEmail,
      region,
    },
    headers,
  };

  try {
    const response = await API.post(amplifyConfig.API.endpoints[0].name, '/get_ec2_instances', init);
    
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ApiRequest = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = props.accessToken;
        const userEmail = props.email;
        const region = props.region;

        const response = await getEC2Instances(accessToken, userEmail, region);
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.region]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div data-testid="ApiRequest">
      <EC2InstanceTable data={data} email={props.email} accessToken={props.accessToken} region={props.region} />
    </div>
  );
};

ApiRequest.propTypes = {
  email: PropTypes.string,
  accessToken: PropTypes.string,
  region: PropTypes.string,
};

ApiRequest.defaultProps = {};

export default ApiRequest;
