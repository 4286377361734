const amplifyConfig = {
    Auth: {
      identityPoolId: 'us-east-1:5ee80968-45ad-40ef-bbaa-9815d8dbf7d1',
      region: 'us-east-1',
      userPoolId: 'us-east-1_YCknOG2zi',
      userPoolWebClientId: '78h2b3non42i1dv1r07egh2keb',
      oauth: {
        domain: 'ec2booter.auth.us-east-1.amazoncognito.com',
        scope: ['email'],
        redirectSignIn: 'https://ec2booter.deloitte.com/',
        redirectSignOut: 'https://ec2booter.deloitte.com/',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
      federationTarget: 'COGNITO_USER_POOL',
      identityProvider: 'AzureAD',
      config: {
        metadataUrl: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921/federationmetadata/2007-06/federationmetadata.xml?appid=f04644cb-82fd-44bf-b4b6-73d0e1712cba',
        serviceProviderName: 'urn:amazon:cognito:sp:us-east-1_YCknOG2zi',
        assertionConsumerServiceURL: 'https://ec2booter.deloitte.com/',
      },
    },
    API: {
      endpoints: [
        {
          name: "AWS WEB APP", // name of the API in API Gateway console
          endpoint: "https://wods1df3hi.execute-api.us-east-1.amazonaws.com/prod",
          region: "us-east-1",
          paths: ['/']
        }
      ]
    }
  }

export default amplifyConfig;