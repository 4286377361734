import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ApiRequest from '../ApiRequest/ApiRequest';
import RegionSelector from '../RegionSelector/RegionSelector';

const AuthenticatedUserView = (props) => {
  const [selectedRegion, setSelectedRegion] = useState('us-east-1');

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
  };

  return (
    <div data-testid="AuthenticatedUserView">
      <div className="App">
        <p>
          Welcome <b>{props.email}</b>!
        </p>
      </div>
      <RegionSelector onRegionChange={handleRegionChange} />
      <ApiRequest email={props.email} accessToken={props.accessToken} region={selectedRegion} />
    </div>
  )
};

AuthenticatedUserView.propTypes = {
  email: PropTypes.string,
  accessToken: PropTypes.string
};

AuthenticatedUserView.defaultProps = {};

export default AuthenticatedUserView;
