// Import the environment variable
const env = process.env.REACT_APP_NAME;

let msalConfig;

if (env === 'd10') {
    msalConfig = require('./d10/msalConfig.js').default;
} else if (env === 'gps_poc') {
    msalConfig = require('./gps_poc/msalConfig.js').default;
} else if (env === 'gps_sa') {
    msalConfig = require('./gps_sa/msalConfig.js').default;
} else {
    msalConfig = require('./ec2booter/msalConfig.js').default;
}

export default msalConfig;